import axios from "../../utils/axios";
import { put } from 'redux-saga/effects'
import { USER_LIST , DISEASE , SYMPTOM, DISTINGUISH , SUBMIT, PATIENT , VISIT , DIV ,KESHI, LILIAO_DISEASE, LILIAO_SYMPTOMS, LILIAO_SUBMIT , INFOS  } from "./actionTypes";
import { users , disease_name , get_symptoms , get_distinguish_symptoms , submit , new_user , list , symptoms , keshi , disease , liliao_symptoms , liliao_submit , info  } from "../../utils/api";

// 发起用户列表请求
export function* getUserList(active) {
    const result = yield axios(`/userapi/${users}`,{
        ...active.params,
        //source:10
    });
    if(result.code===1){
        const user = result.data;
        // 派发给reducers
        yield put({
            type: USER_LIST.GET_LIST,
            user,
            params:active.params
        })
    }
}

// 搜索病名
export function* getDiseaseName(active) {
    const result = yield axios(`/kfApi/${disease_name}`,active.params)
    if(result.code===1){
        const data = result.data;
        yield put({
            type: DISEASE.GET_DISEASE_NAME,
            data
        }) 
    }
}

// 根据病名或主诉获取症状列表(用于单选)
export function* getSymptom(active) {
    const result = yield axios(`/kfApi/${get_symptoms}`,active.params,'POST')
    if(result.code===1){
        const data = result.data;
        yield put({
            type: SYMPTOM.POST_SYMPTOM,
            data
        }) 
    }
}

// 根据单个症状id获取辨别症状列表(用于多选)
export function* getDistinguish(active) {
    const result = yield axios(`/kfApi/${get_distinguish_symptoms}`,active.params)
    if(result.code===1){
        const data = result.data;
        yield put({
            type: DISTINGUISH.GET_DISTINGUISH,
            data
        }) 
    }
}

//提交报告
export function* getSubmit(active) {
    const result = yield axios(`/kfApi/${submit}`,active.params,'POST')
    if(result.code===1){
        const data = result.data;
        yield put({
            type: SUBMIT.POST_SUBMIT_REPORT,
            data
        })
    }
}

//新增患者
export function* getAddUser(active) {
    const result = yield axios(`/userapi/${new_user}`,active.params,'POST')
    if(result.code===1){
        const data = result;
        yield put({
            type: PATIENT.GET_PATIENT_USER,
            data
        })
    }
}

//就诊记录
export function* getVisit(active) {
    const result = yield axios(`/kfApi/${list}`,active.params)
    const data = result.data;
    if(result.code===1){
        yield put({
            type: VISIT.GET_VISIT_RECORD,
            data
        }) 
    }
}

//自定义病症
export function* getDivSubmit(active) {
    const result = yield axios(`/kfApi/${symptoms}`,active.params.form,'POST');
    if(result.code===1){
        const data = result.data;
        if(active.params.data.symptom_id){
            active.params.data.symptom_list.push(data.id);
        }else{
            active.params.data.symptom_id=data.id;
        }
        yield put({
            type: DIV.POST_DIV_SUBMIT,
            params:active.params.data
        }) 
    }
}

//所有科室
export function* getKeshi(active) {
    const result = yield axios(`/kfApi/${keshi}`,{sex:active.params});
    if(result.code===1){
        const data = result.data;
        yield put({
            type: KESHI.GET_KESHI_DATA,
            params:{
                keshi_id:data[0].id,
                sex:active.params
            },
            data
        }) 
    }
}

// 根据科室id获取所有的病名列表(单选)
export function* getLiliaoDisease(active) {
    const result = yield axios(`/kfApi/${disease}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: LILIAO_DISEASE.GET_DISEASE_DATA,
            data
        }) 
    }
}

// 根据病名获取所有的症状列表(多选)
export function* getLiliaoSymptoms(active) {
    const result = yield axios(`/kfApi/${liliao_symptoms}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: LILIAO_SYMPTOMS.GET_SYMPTOMS_DATA,
            data
        }) 
    }
}

// 理疗提交报告
export function* getLiliaoSubmit(active) {
    const result = yield axios(`/kfApi/${liliao_submit}`,active.params,'POST');
    if(result.code===1){
        const data = result.data;
        yield put({
            type: LILIAO_SUBMIT.POST_SUBMIT,
            data
        }) 
    }
}

// 用户信息
export function* getInfo(active) {
    const result = yield axios(`/authapi/${info}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: INFOS.GET_INFO,
            data
        }) 
    }
}