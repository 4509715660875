import axios from "../../utils/axios";
import { admin_login , code_img } from "../../utils/api";
import { put } from 'redux-saga/effects'
import { CODE , LOGIN } from "./actionTypes";

// 登录 - 提交
export function* postSubmit(active) {
    const result = yield axios(`/authapi/${admin_login}`,active.params.params,'POST')
    yield put({
        type: LOGIN.POST_LOGIN_SUBMIT,
        data:{
            history:active.params.history,
            result
        }
    }) 
}

// 登录 - 验证码
export function* getCode() {
    const result = yield axios(`/authapi/${code_img}`)
    const data = result.data;
    yield put({
        type: CODE.POST_CODE_IMG,
        data
    }) 
}