import {
    REPORT,
    REPORT_STATE,
    COMPLETE,
    SEARCH
} from './actionTypes'

// 数据池
const defaultState = {
    reportInfo:{},
    active:0,
    handleSelect:[],      //id组
    searchList:[],        //选择方剂滋补药列表
    piecesList:[],        //中药饮片列表
    westList:[],          //西药列表
    searchStorage:[],     //选择方剂滋补药暂存数据
    westStorage:[],       //西药
    piecesStorage:[]      //中药饮片
};

var Reducer = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch(action.type){
        case REPORT.GET_REPORT_INFO:        //报告详情
            console.log(action.data);
            newState.reportInfo = action.data;
            return newState;
        case REPORT_STATE:                  //修改状态
            newState[action.params.key] = action.params.value;
            return newState;
        case COMPLETE.POST_COMPLETE_REPORT:  //完诊
            window.location.href=`/report/${action.data.report_token}`;
            return newState;
        case SEARCH.GET_SEARCH_ING:          //相关搜索
            if(action.data.type===4){
                newState.piecesList = action.data.data;
            }else if(action.data.type===2){
                newState.westList = action.data.data;
            }else{
                newState.searchList = action.data.data;
            }
            return newState;
        default:
            return state;
    }
}

export default Reducer;