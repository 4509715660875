//会员列表
export const VIP_LIST = {
    LIST:'VIP_LIST',
    POST_LIST:'POST_VIP_LIST'
};

//操作员列表
export const OPERATOR_LIST = {
    LIST:'OPERATOR_LIST',
    GET_LIST:'GET_OPERATOR_LIST'
};

// 销售(或购买)订单列表
export const SALE_LIST = {
    LIST:'SALE_LIST',
    POST_LIST:'POST_SALE_LIST'
};

// 订单详情
export const SALE_INFO = {
    INFO:'SALE_INFO',
    POST_INFO:'POST_SALE_INFO'
};

// 单卡详情
export const CARD_INFO = {
    INFO:'CARD_INFO',
    POST_INFO:'POST_CARD_INFO'
};

// 卡列表
export const CARD_LIST = {
    LIST:'CARD_LIST',
    GET_LIST:'GET_CARD_LIST'
};

// 卡激活
export const CARD_ACTIVE = {
    ACTIVE:'CARD_ACTIVE',
    POST_ACTIVE:'POST_CARD_ACTIVE'
};

// 套餐列表
export const MEAL_LIST = {
    LIST:'MEAL_LIST',
    POST_LIST:'POST_MEAL_LIST'
};

//获取系统套餐详情及服务列表
export const MEAL_INFO = {
    INFO:'MEAL_INFO',
    POST_INFO:'POST_MEAL_INFO'
};

//销售套餐(租户端)
export const COMPANY_SELL = {
    SELL:'COMPANY_SELL',
    POST_SELL:'POST_COMPANY_SELL'
};

//修改 状态
export const MODIFY = "MODIFY";

//服务操作记录(明细)列表
export const CARD_OPERATE = {
    OPERATE:'CARD_OPERATE',
    POST_OPERATE:'POST_OPERATE'
};

//确认支付
export const ORDER_CONFIRM = {
    CONFIRM:'ORDER_CONFIRM',
    GET_CONFIRM:'GET_ORDER_CONFIRM'
};

//获取支付图片
export const PAY_IMAGE = {
    IMAGE:'PAY_IMAGE',
    GET_PAY_IMAGE:'GET_PAY_IMAGE'
};

//卡销售
export const COMPANY_SELL_CODE = {
    CODE:'COMPANY_SELL_CODE',
    POST_COMPANY_SELL_CODE:'POST_COMPANY_SELL_CODE'
};

//撤销激活
export const CANCEL_ACTIVE = {
    ACTIVE:'CANCEL_ACTIVE',
    POST_CANCEL_ACTIVE:'POST_CANCEL_ACTIVE'
};

//现金卡充值
export const CHONG_ZHI = {
    CHONG_ZHI:'CHONG_ZHI',
    POST_CHONG_ZHI:'POST_CHONG_ZHI'
};

//现金卡充值确认支付
export const CHONG_ZHI_PAY = {
    CHONG_ZHI_PAY:'CHONG_ZHI_PAY',
    GET_CHONG_ZHI_PAY:'GET_CHONG_ZHI_PAY'
};