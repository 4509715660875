import {
    SEF_REPORT,
    change,
    changeTwo,
    MODIFY,
    THIR_PARTY,
    ZHIBIAO_REPORT,
    WENQUAN_REPORT,
    WENQUAN_REPORT_EDIT
} from './actionTypes';

const echarts = require('echarts/lib/echarts');
//折
require('echarts/lib/chart/gauge');
require('echarts/lib/component/grid');
require('echarts/lib/chart/line');

// 数据池
const defaultState = {
    service:null,
    quanmai:null,
    lichuang:'',
    module:{
        renovate:true,
        data:[]
    },
    thir:[],
    zhibiao:null,
    zhibiaoJson:null,
    wenjuan:null,
    wenjuan_list:null
};

var Reducer = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch(action.type){
        case SEF_REPORT.GET_SEF_REPORT:
            if(action.data.reportInfo){
                let info = JSON.parse(action.data.reportInfo).data;
                //洗数据
                let big = [] , little = [] ,bigData = [],littleData=[];
                for( let item in info.syndrome_danger ){
                    let hot = 0 , syndrome_danger = info.syndrome_danger;
                    for(let i=0,n=syndrome_danger[item].list.length;i<n;i++){
                        if(syndrome_danger[item].list[i].risk_index){
                            hot++;
                            little.push(syndrome_danger[item].list[i].disease_name);
                            info.syndrome_danger[item].list[i].key = syndrome_danger[item].name;
                            littleData.push(syndrome_danger[item].list[i]);
                        }
                    }
                    if(hot>0){
                        big.push(syndrome_danger[item].name);
                        bigData.push(syndrome_danger[item]);
                    }
                }
                info.big = big;
                info.little = little;
                info.littleData = littleData;
                newState.quanmai = info;

                let module_list = info.module_list;
                //模块
                if(module_list){
                    for(let i=0,n=module_list.length;i<n;i++){
                        let description_list = info.module_list[i].description_list;
                        let hoo = 0;
                        for(let description in description_list){
                            if(hoo == 0){
                                module_list[i].description_list[description].active = true;
                            }
                            if(module_list[i].description_list[description].list){
                                module_list[i].description_list[description].list[0].active = true;
                            }   
                            hoo++;
                        }
                    }
                }

                newState.module = {
                    renovate:!state.module.renovate,
                    data:module_list?module_list:[]
                }

                setTimeout(function(){
                    Meter('diag-target-1',info.score);
                },1)
            }else{
                newState.module = {
                    renovate:!state.module.renovate,
                    data:[]
                }
                newState.quanmai = null;
            }
            return newState;
        case change.change:
            newState.module = changeFun(state,action.params);
            return newState;
        case changeTwo.changeTwo:
            newState.module = changeTwoFun(state,action.params);
            return newState;
        case THIR_PARTY.THIR_PARTY:
            newState.thir = [];
            newState.lichuang = '';
            return newState;
        case THIR_PARTY.GET_THIR_PARTY:
            newState.thir = action.data;
            return newState;
        case THIR_PARTY.THIR_PARTY_MAIQUAN:
            newState.thir = action.data;
            return newState;
        case THIR_PARTY.THIR_PARTY_LICHUANG:
            newState.thir = action.data;
            return newState;
        case THIR_PARTY.THIR_PARTY_SELF:
            newState.thir = action.data;
            return newState;
        case THIR_PARTY.THIR_PARTY_YINGYAN:
            newState.thir = action.data;
            return newState;
        case THIR_PARTY.THIR_PARTY_ZHIBIAO:
            newState.thir = action.data;
            return newState;
        case THIR_PARTY.THIR_PARTY_SHUIKE:
            newState.thir = action.data;
            return newState;
        case THIR_PARTY.THIR_PARTY_WENQUAN:
            newState.thir = action.data;
            return newState;
        case THIR_PARTY.THIR_PARTY_FANGAN:
            newState.thir = action.data;
            return newState;
        case MODIFY:                         //修改 状态
            action.params.map((item)=>{
                newState[item.key] = item.value;
            })
            return newState;
        case ZHIBIAO_REPORT.GET_ZHIBIAO_REPORT:
            newState.zhibiao = JSON.parse(action.data);
            newState.zhibiao.syndrome_desc = newState.zhibiao.syndrome_desc?JSON.parse(newState.zhibiao.syndrome_desc):"";
            newState.zhibiaoJson = action.data;
            return newState;
        case WENQUAN_REPORT.GET_WENQUAN_REPORT:
            if(action.date){
                newState.wenquan = action.data;
                newState.wenquan.reportInfo = newState.wenquan.reportInfo?JSON.parse(newState.wenquan.reportInfo):"";
                console.log(newState.wenquan.reportInfo);
                if(action.fun){
                    action.fun(action.data.reportInfo);
                }
            }else{
                newState.wenjuan_list = action.data;
            }
            return newState;
        default:
            return state;
    }
};

//一级目录
function changeFun(state,params){
    let module = state.module.data;
    if(module[params.index].description_list){
        let i = 0;
        for(let item in module[params.index].description_list){
            module[params.index].description_list[item].active = false;
            if(i==params.index1){
                module[params.index].description_list[item].active = true;
            }
            i++;
        }        
    }
    return {
        renovate:!state.module.renovate,
        data:module
    }
}

//二级目录
function changeTwoFun(state,params){
    let module = state.module.data;
    if(module[params.index].description_list){
        for(let item in module[params.index].description_list){
            if(module[params.index].description_list){
                let i = 0;
                for(let item1 in module[params.index].description_list[item].list){
                    module[params.index].description_list[item].list[item1].active=false;
                    if(i==params.index2){
                        module[params.index].description_list[item].list[params.index2].active=true
                    }
                    i++;
                }
            }
        }        
    }
    return {
        renovate:!state.module.renovate,
        data:module
    }
}


export default Reducer;

//仪表盘
function Meter(dom,score){
    var chartDom = document.getElementById(dom);
    echarts.dispose(chartDom);
    var myChart = echarts.init(chartDom);
    var option;
    option = {
        series: [{
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 100,
            splitNumber: 0,
            axisLine: {
                lineStyle: {
                    width: 6,
                    color: [
                        [0.5, '#F36440'],
                        [0.65, '#F89143'],
                        [0.8, '#ECDB44'],
                        [0.9, '#5CC7BC'],
                        [1, '#83D186']
                    ]
                }
            },
            splitLine: {
                length: 20,
                lineStyle: {
                    color: 'auto',
                    width: 5
                }
            },
            axisLabel: {
                color: '#464646',
                fontSize: 15,
                distance: -60,
                formatter: function (value) {
                    return value?value:''
                }
            },
            detail: {
                fontSize: 16,
                offsetCenter: [0, '40%'],
                valueAnimation: false,
                formatter: function (value) {
                    let score = Math.round(value);
                    let score_text = '';
                    if(0<score && score<=50){
                        score_text = '可能患有疾病，建议就医检查';
                    }
                    if(50<score && score<=65 ){
                        score_text= "身体极不平衡，建议马上调理";
                    }
                    if(65<score && score<=80 ){
                        score_text= "身体较不平衡，建议马上调理";
                    }
                    if(80<score && score<=90 ){
                        score_text= "身体较为平衡，建议适当调理";
                    }
                    if(90<score && score<=100 ){
                        score_text= "身体较为平衡，建议适当调理";
                    }
                    return score_text;
                },
                color: 'auto'
            },
            data: [{
                value: score
            }]
        }]
    };
    option && myChart.setOption(option);
}