import axios from "../../utils/axios";
import { put } from 'redux-saga/effects'
import {
    SEF_REPORT,
    THIR_PARTY,
    ZHIBIAO_REPORT,
    WENQUAN_REPORT,
    WENQUAN_REPORT_EDIT
} from './actionTypes'
import { self_report , third_party_date , zhibiao_report , wenquan_report , wenquan_report_edit } from "../../utils/api";


//我方报告
export function* getQuanmaiReport(active) {
    const result = yield axios(`/kfApi/${self_report}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: SEF_REPORT.GET_SEF_REPORT,
            data
        }) 
    }
}

// 获取日期列表(单个的)
export function* getThirParty(active) {
    const result = yield axios(`/kfApi/${third_party_date}`,{
        user_id:active.params.user_id,
        type:active.params.type,
        phone:active.params.phone,
    });
    if(result.code===1){
        const data = result.data;
        if(active.params.go && data.length>0){
            if(active.params.type=='lichuang'){
                yield put({
                    type: THIR_PARTY.THIR_PARTY_LICHUANG,
                    params:{
                        phone:active.params.phone,
                        user_id:active.params.user_id,
                        date:data[0]
                    },
                    data
                })
            }
            if(active.params.type=='self'){
                yield put({
                    type: THIR_PARTY.THIR_PARTY_SELF,
                    params:{
                        phone:active.params.phone,
                        user_id:active.params.user_id,
                        date:data[0]
                    },
                    data
                })
            }
            if(active.params.type=='yingyan'){
                yield put({
                    type: THIR_PARTY.THIR_PARTY_YINGYAN,
                    params:{
                        phone:active.params.phone,
                        user_id:active.params.user_id,
                        date:data[0]
                    },
                    data
                })
            }
            if(active.params.type=='maiquan'){
                yield put({
                    type: THIR_PARTY.THIR_PARTY_MAIQUAN,
                    params:{
                        phone:active.params.phone,
                        user_id:active.params.user_id,
                        date:data[0]
                    },
                    data
                })
            }
            if(active.params.type=='zhibiao'){
                yield put({
                    type: THIR_PARTY.THIR_PARTY_ZHIBIAO,
                    params:{
                        user_id:active.params.user_id,
                        phone:active.params.phone,
                        date:data[0]
                    },
                    data
                })
            }
            if(active.params.type=='wenquan'){
                yield put({
                    type: THIR_PARTY.THIR_PARTY_WENQUAN,
                    params:{
                        user_id:active.params.user_id,
                        phone:active.params.phone,
                        date:data[0],
                        fun:active.params.fun
                    },
                    data
                })
            }
            if(active.params.type=='fangan'){
                yield put({
                    type: THIR_PARTY.THIR_PARTY_FANGAN,
                    params:{
                        user_id:active.params.user_id,
                        phone:active.params.phone,
                        date:data[0],
                        fun:active.params.fun
                    },
                    data
                })
            }
            if(active.params.type=='sleep'){
                yield put({
                    type: THIR_PARTY.THIR_PARTY_SHUIKE,
                    params:{
                        user_id:active.params.user_id,
                        phone:active.params.phone,
                        date:data[0].date,
                        sn:data[0].code,
                        fun:active.params.fun
                    },
                    data
                })
            }
        }else{
            yield put({
                type: THIR_PARTY.GET_THIR_PARTY,
                data
            })
        }
    }
}


//健康检测指标报告
export function* getZhibiaoReport(active) {
    const result = yield axios(`/kfApi/${zhibiao_report}`,active.params);
    if(result.code===1){
        const data = result.data;
        console.log(data);
        yield put({
            type: ZHIBIAO_REPORT.GET_ZHIBIAO_REPORT,
            data
        }) 
    }
}
//健康问券报告
export function* getWenquanReport(active) {
    const result = yield axios(`/kfApi/${wenquan_report}`,{
        user_id:active.params.user_id,
        phone:active.params.phone,
        date:active.params.date,
    });
    if(result.code===1){
        const data = result.data;
        yield put({
            type: WENQUAN_REPORT.GET_WENQUAN_REPORT,
            fun:active.params.fun,
            date:active.params.date,
            data
        }) 
    }
}
//健康问券创建或修改
export function* postWenquanReportEdit(active) {
    const result = yield axios(`/kfApi/${wenquan_report_edit}`,active.params,'POST');
    if(result.code===1){
        const data = result.data;
        yield put({
            type: WENQUAN_REPORT_EDIT.POST_WENQUAN_REPORT_EDIT,
            data
        }) 
    }
}