//我方报告详情
export const SEF_REPORT = {
    SEF_REPORT:'SEF_REPORT',
    GET_SEF_REPORT:'GET_SEF_REPORT'
};

//修改一级目录
export const change = {
    change:'change'
};

//修改二级目录
export const changeTwo = {
    changeTwo:'changeTwo'
};

//修改 状态
export const MODIFY = "modify";

//获取日期列表(单个的)
export const THIR_PARTY = {
    THIR_PARTY:'THIR_PARTY',
    GET_THIR_PARTY:'GET_THIR_PARTY',
    THIR_PARTY_LICHUANG:'THIR_PARTY_LICHUANG',
    THIR_PARTY_SELF:'THIR_PARTY_SELF',
    THIR_PARTY_MAIQUAN:'THIR_PARTY_MAIQUAN',
    THIR_PARTY_YINGYAN:'THIR_PARTY_YINGYAN',
    THIR_PARTY_ZHIBIAO:'THIR_PARTY_ZHIBIAO',
    THIR_PARTY_WENQUAN:'THIR_PARTY_WENQUAN',
    THIR_PARTY_FANGAN:'THIR_PARTY_FANGAN',
    THIR_PARTY_SHUIKE:'THIR_PARTY_SHUIKE'
};

//健康检测指标报告
export const ZHIBIAO_REPORT = {
    ZHIBIAO_REPORT:'ZHIBIAO_REPORT',
    GET_ZHIBIAO_REPORT:'GET_ZHIBIAO_REPORT'
};

//健康问券报告
export const WENQUAN_REPORT = {
    WENQUAN_REPORT:'WENQUAN_REPORT',
    GET_WENQUAN_REPORT:'GET_WENQUAN_REPORT'
};

//健康问券创建或修改
export const WENQUAN_REPORT_EDIT = {
    WENQUAN_REPORT_EDIT:'WENQUAN_REPORT_EDIT',
    POST_WENQUAN_REPORT_EDIT:'POST_WENQUAN_REPORT_EDIT'
};
