import axios from "../../utils/axios";
import { put } from 'redux-saga/effects'
import {
    USER_MEAL,
    USER_SERVICE,
    BIND_DISEASE,
    DICTIONARIES,
    SERVICE_OPERATE,
    MEAL_DEFINE,
    MEAL_REVOKE,
    SERVICE_LIST,
    OSS,
    UNBIND_DISEASE
} from './actionTypes'
import { user_set_meal , user_service , bind_disease , user_service_operate , dictionaries , meal_cancel , confirm_pay , service_list , oss , unbind_disease } from "../../utils/api";
import { message } from 'antd';

// 根据患者id来获取用户套餐列表
export function* getUserMealList(active) {
    const result = yield axios(`/kfApi/${user_set_meal}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: USER_MEAL.GET_MEAL,
            data
        }) 
    }
}

// 获取患者套餐内的服务列表
export function* postUserServiceList(active) {
    const result = yield axios(`/kfApi/${user_service}`,active.params,'POST');
    if(result.code == 1){
        const data = result.data;
        if(!data.code){
            yield put({
                type: USER_SERVICE.POST_SERVICE,
                params:{
                    token:data.report_token
                },
                data
            })
        }else if(data.code==1){
            message.error(data.msg);
        }else if(data.code==2){
            yield put({
                type: USER_SERVICE.POST_SERVICE_DTDA,
                data
            })
        }
    }
}

// 患者套餐绑定专项理疗(病名)
export function* getBindDiseaseList(active) {
    const result = yield axios(`/kfApi/${bind_disease}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: BIND_DISEASE.GET_DISEASE,
            params:active.params,
            data
        }) 
    }
}

// 患者套餐解绑专项理疗(病名)
export function* getUnbindDisease(active) {
    const result = yield axios(`/kfApi/${unbind_disease}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: UNBIND_DISEASE.GET_UNBIND_DISEASE,
            params:{id:active.params.id},
            data
        }) 
    }
}

//获取系统字典列表
export function* getDictionariesList(active) {
    const result = yield axios(`/userapi/${dictionaries}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: DICTIONARIES.GET_DICTIONARIES,
            data
        }) 
    }
}

// 操作患者套餐内的服务
export function* getServiceOperate(active) {
    const result = yield axios(`/kfApi/${user_service_operate}`,active.params.data, 'POST');
    if(result.code===1){
        const data = result.data;
        if(active.params.type==1){
            yield put({
                type: SERVICE_OPERATE.REPORT,
                params:{
                    token:active.params.data.report_token
                },
                data
            })
        }else{
            yield put({
                type: SERVICE_OPERATE.POST_SERVICE_OPERATE,
                data
            })
        }
    }
}

//撤销套餐
export function* getMealRevoke(active) {
    const result = yield axios(`/kfApi/${meal_cancel}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: MEAL_REVOKE.GET_MEAL_REVOKE,
            data
        }) 
    }
}

//确定套餐
export function* getMealDefine(active) {
    const result = yield axios(`/kfApi/${confirm_pay}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: MEAL_DEFINE.GET_MEAL_DEFINE,
            data
        }) 
    }
}

//获取服务项目列表
export function* getServiceList(active) {
    const result = yield axios(`/kfApi/${service_list}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: SERVICE_LIST.GET_SERVICE_LIST,
            data
        }) 
    }
}

// 上传oss的配置
export function* getOss(active) {
    const result = yield axios(`/ossapi/${oss}`,active.params)
    if(result.code===1){
        const data = result.data;
        const formData = new FormData();
        formData.append('OSSAccessKeyId', data.OSSAccessKeyId);
        formData.append('Policy', data.Policy);
        formData.append('Signature', data.Signature);
        formData.append('Key', `${data.Key}${new Date().getTime()}/1.png`);
        formData.append('Callback', data.Callback);
        formData.append('File', active.params);
        yield put({
            type: OSS.GET_IMG,
            url:data['Host'],
            formData
        }) 
    }
}

//上传oss
export function* getOssImg(active) {
    const result = yield axios(`/aliyuncs`,active.formData,'POST');
    if(result.Code===0){
        const data = result.Data;
        yield put({
            type: OSS.POST_IMG,
            data
        }) 
    }
}