import React,{ Suspense , lazy } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

//页面
const List = lazy(() => import('./pages/list/index'));    //客户列表
const Home = lazy(() => import('./pages/home/index'));    //首页
const Login = lazy(() => import('./pages/login/index'));  //登录
const Report = lazy(() => import('./pages/report/index'));  //报告详情
const Operate = lazy(() => import('./pages/operate/index'));  //操作
const Card = lazy(() => import('./pages/card/index'));  //卡管理
const Subscribe = lazy(() => import('./pages/subscribe/index'));    //预约
const Study = lazy(() => import('./pages/study/index'));    //预约
const N404 = lazy(() => import('./pages/404'));     //404


//路由组件
class Routes extends React.PureComponent {
    componentDidMount(){
        //计算
        if(window.innerWidth>window.innerHeight){
            let html = document.getElementsByTagName('html')[0];
            html.style.fontSize = `${window.innerHeight*0.13}px`;
        }
    }
    render(){
        return(
            <Router>
                <Suspense fallback={<div>加载中...</div>}>
                    <Switch>
                        <Route exact path="/" component={List}/>
                        <Route exact path="/diagnose" component={Home}/>
                        <Route exact path="/login" component={Login}/>
                        <Route exact path="/card" component={Card}/>
                        <Route exact path="/report/:id" component={Report}/>
                        <Route exact path="/subscribe" component={Subscribe}/>
                        <Route exact path="/operate" component={Operate}/>
                        <Route exact path="/study" component={Study}/>
                        <Route path="/*" component={N404}/>
                    </Switch>
                </Suspense>
            </Router>
        )
    }
}

export default Routes;