//请求
import { takeEvery } from 'redux-saga/effects'
//诊断
import { USER_LIST , DISEASE , SYMPTOM, DISTINGUISH , SUBMIT , PATIENT , VISIT , DIV , KESHI, LILIAO_DISEASE, LILIAO_SYMPTOMS, LILIAO_SUBMIT , INFOS
 } from "./home/actionTypes";
import { getUserList , getDiseaseName , getSymptom , getDistinguish , getSubmit , getAddUser , getVisit , getDivSubmit , getKeshi , getLiliaoDisease , getLiliaoSymptoms , getLiliaoSubmit , getInfo  } from "./home/sagas";
//登录
import { LOGIN , CODE } from "./login/actionTypes";
import { postSubmit , getCode } from "./login/sagas";
//报告
import { REPORT , COMPLETE , SEARCH } from "./report/actionTypes";
import { getReport , getComplete , getSearch } from "./report/sagas";
//客户列表
import { VIP_LIST, OPERATOR_LIST, SALE_LIST, SALE_INFO, CARD_INFO , CARD_LIST , CARD_ACTIVE , MEAL_LIST , MEAL_INFO , COMPANY_SELL , CARD_OPERATE , ORDER_CONFIRM , PAY_IMAGE , COMPANY_SELL_CODE , CANCEL_ACTIVE , CHONG_ZHI , CHONG_ZHI_PAY } from "./list/actionTypes";
import { postVipList , getOperatorList , postSaleList , postSaleInfo , postCardInfo , getCardList , postCardActive , postMealList , postMealInfo , postCompanySell , postCardOperate , getOrderConfirm , getPayImage , postCompanySellCode , postCancelActive , postChongzhiActive , postChongzhipayActive } from "./list/sagas";
//操作
import { USER_MEAL , USER_SERVICE , BIND_DISEASE , DICTIONARIES , SERVICE_OPERATE ,MEAL_DEFINE, MEAL_REVOKE , SERVICE_LIST , OSS , UNBIND_DISEASE } from "./operate/actionTypes";
import { getUserMealList , postUserServiceList , getBindDiseaseList , getDictionariesList , getServiceOperate , getMealDefine , getMealRevoke , getServiceList , getOss , getOssImg , getUnbindDisease } from "./operate/sagas";
//预约
import { SUBSCRIBE , SUBSCRIBE_CANCEL , SUBSCRIBE_UPDATE , STORE , TIMEADD, TIMELIST } from "./subscribe/actionTypes";
import { postSubscribeList , getSubscribeCancel , postSubscribeUpdate , getStoreList , postTimeList , postTimeAdd } from "./subscribe/sagas";
//康养理疗
import { SEF_REPORT , THIR_PARTY , ZHIBIAO_REPORT , WENQUAN_REPORT , WENQUAN_REPORT_EDIT } from "./liliao/actionTypes";
import { getQuanmaiReport , getThirParty , getZhibiaoReport , getWenquanReport , postWenquanReportEdit } from "./liliao/sagas";

function* mySaga() {
    // 接受action传递的任务
    yield takeEvery(USER_LIST.LIST, getUserList);
    yield takeEvery(LOGIN.LOGIN_SUBMIT, postSubmit);
    yield takeEvery(CODE.CODE_IMG, getCode);
    yield takeEvery(DISEASE.DISEASE_NAME, getDiseaseName);
    yield takeEvery(SYMPTOM.SYMPTOM_LOOK, getSymptom);
    yield takeEvery(DISTINGUISH.DISTINGUISH_LOOK, getDistinguish);
    yield takeEvery(SUBMIT.SUBMIT_REPORT, getSubmit);
    yield takeEvery(REPORT.REPORT_INFO, getReport);
    yield takeEvery(COMPLETE.COMPLETE_REPORT, getComplete);
    yield takeEvery(PATIENT.PATIENT_USER, getAddUser);
    yield takeEvery(SEARCH.SEARCH_ING, getSearch);
    yield takeEvery(VISIT.VISIT_RECORD, getVisit);
    yield takeEvery(DIV.DIV_SUBMIT, getDivSubmit);
    yield takeEvery(DIV.POST_DIV_SUBMIT, getSubmit);
    yield takeEvery(KESHI.KESHI_DATA, getKeshi);
    yield takeEvery(LILIAO_DISEASE.DISEASE_DATA, getLiliaoDisease);
    yield takeEvery(LILIAO_SYMPTOMS.SYMPTOMS_DATA, getLiliaoSymptoms);
    yield takeEvery(LILIAO_SUBMIT.SUBMIT, getLiliaoSubmit);
    yield takeEvery(KESHI.GET_KESHI_DATA, getLiliaoDisease);
    yield takeEvery(VIP_LIST.LIST, postVipList);
    yield takeEvery(OPERATOR_LIST.LIST, getOperatorList);
    yield takeEvery(SALE_LIST.LIST, postSaleList);
    yield takeEvery(SALE_INFO.INFO, postSaleInfo);
    yield takeEvery(CARD_INFO.INFO, postCardInfo);
    yield takeEvery(CARD_LIST.LIST, getCardList);
    yield takeEvery(CARD_ACTIVE.ACTIVE, postCardActive);
    yield takeEvery(MEAL_LIST.LIST, postMealList);
    yield takeEvery(MEAL_INFO.INFO, postMealInfo);
    yield takeEvery(COMPANY_SELL.SELL, postCompanySell);
    yield takeEvery(CARD_OPERATE.OPERATE, postCardOperate);
    yield takeEvery(USER_MEAL.MEAL, getUserMealList);
    yield takeEvery(USER_SERVICE.SERVICE, postUserServiceList);
    yield takeEvery(BIND_DISEASE.DISEASE, getBindDiseaseList);
    yield takeEvery(ORDER_CONFIRM.CONFIRM, getOrderConfirm);
    yield takeEvery(BIND_DISEASE.GET_DISEASE, postUserServiceList);
    yield takeEvery(DICTIONARIES.DICTIONARIES, getDictionariesList);
    yield takeEvery(SERVICE_OPERATE.OPERATE, getServiceOperate);
    yield takeEvery(SERVICE_OPERATE.REPORT, getReport);
    yield takeEvery(MEAL_DEFINE.DEFINE, getMealDefine);
    yield takeEvery(MEAL_REVOKE.REVOKE, getMealRevoke);
    yield takeEvery(PAY_IMAGE.IMAGE, getPayImage);
    yield takeEvery(SUBSCRIBE.SUBSCRIBE_LIST, postSubscribeList);
    yield takeEvery(SUBSCRIBE_CANCEL.SUBSCRIBE_CANCEL, getSubscribeCancel);
    yield takeEvery(SUBSCRIBE_UPDATE.SUBSCRIBE_UPDATE, postSubscribeUpdate);
    yield takeEvery(STORE.STORE_LIST, getStoreList);
    yield takeEvery(SUBSCRIBE_CANCEL.GET_SUBSCRIBE_CANCEL, postSubscribeList);
    yield takeEvery(SUBSCRIBE_UPDATE.POST_SUBSCRIBE_UPDATE, postSubscribeList);
    yield takeEvery(SERVICE_LIST.SERVICE_LIST, getServiceList);
    yield takeEvery(COMPANY_SELL_CODE.CODE, postCompanySellCode);
    yield takeEvery(INFOS.INFO, getInfo);
    yield takeEvery(CANCEL_ACTIVE.ACTIVE, postCancelActive);
    yield takeEvery(OSS.IMG, getOss);
    yield takeEvery(OSS.GET_IMG, getOssImg);
    yield takeEvery(TIMEADD.TIME_ADD, postTimeAdd);
    yield takeEvery(TIMELIST.TIME_LIST, postTimeList);
    yield takeEvery(UNBIND_DISEASE.UNBIND_DISEASE, getUnbindDisease);
    yield takeEvery(UNBIND_DISEASE.GET_UNBIND_DISEASE, getUserMealList);
    yield takeEvery(SEF_REPORT.SEF_REPORT, getQuanmaiReport);
    yield takeEvery(THIR_PARTY.THIR_PARTY, getThirParty);
    yield takeEvery(THIR_PARTY.THIR_PARTY_WENQUAN, getWenquanReport);
    yield takeEvery(THIR_PARTY.THIR_PARTY_ZHIBIAO, getZhibiaoReport);
    yield takeEvery(THIR_PARTY.THIR_PARTY_SELF, getQuanmaiReport);
    yield takeEvery(ZHIBIAO_REPORT.ZHIBIAO_REPORT, getZhibiaoReport);
    yield takeEvery(WENQUAN_REPORT.WENQUAN_REPORT, getWenquanReport);
    yield takeEvery(WENQUAN_REPORT_EDIT.WENQUAN_REPORT_EDIT, postWenquanReportEdit);
    yield takeEvery(CHONG_ZHI.CHONG_ZHI, postChongzhiActive);
    yield takeEvery(CHONG_ZHI_PAY.CHONG_ZHI_PAY, postChongzhipayActive);
}


export default mySaga;